body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2.step-title {
  margin: 0;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 24px;
}
