@import "../../../resources/scss/variables";

.centered-container {
  font-family: $primary-regular-font;
  color: $secondary-blue-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 20px;

  &__content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 36px;
    align-items: start;
    max-width: 980px;
    padding: 5px;
    text-align: left;

    &-image {
      width: 166px;
      height: 262px;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;

      &-heading {
        margin: 0 0 10px;
        font-size: 38px;
        font-weight: 700;
        line-height: 46px;
      }

      &-paragraph {
        margin: 0 0 10px;
        font-size: 24px;
        font-weight: 600;
        line-height: 32px;
      }

      &-span {
        margin: 0 0 10px;
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
      }
    }
  }
}

@media (max-width: 600px) {
  .centered-container {
    &__content {
      display: block;
      text-align: center;
      padding: 0;

      &-image {
        margin: 0 auto;
        width: 100px;
        height: auto;
      }

      &-text {
        &-heading {
          text-align: center;
          font-size: 30px;
          font-weight: 700;
          line-height: 38px;
        }

        &-paragraph {
          font-size: 20px;
          font-weight: 600;
          line-height: 28px;
        }

        &-span {
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
        }
      }
    }
  }
}
