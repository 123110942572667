@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap');
@import "./resources/scss/variables";

.app {
  text-align: center;
  font-family: 'Source Sans Pro', sans-serif;

  .af-content {
    background: white;
    margin: $content-margin;
    min-height: calc(100vh - 180px);

    @media screen and (max-width: $md) {
      margin: $content-margin-md;
    }
  }

  .af-layout {
    background: white;
  }


  // Card
  .ant-card {
    background: #FFFFFF;
    box-shadow: 0px 3px 24px -4px rgba(0, 0, 0, 0.1);
  }

  .ant-card-body {
    padding: 24px;
  }

  a,
  .ant-btn-link,
  .ant-btn-link:hover {
    color: #15257A;
    font-weight: 600;
  }

  // Alert - type info
  .ant-alert-info {
    margin-bottom: 50px;
    text-align: left;
    background-color: $primary-blue-light-color;
    border: 1px solid $collapse-active-border-color;
    color: $nineth-gray-color;
  }
}

.spin-app.ant-spin.ant-spin-lg.ant-spin-spinning.ant-spin-show-text{
  top: 15%;
  color: $primary-blue-color;
  .ant-spin-text{
    font: $secundary-regular-font;
  }
}

.table__row__icon--loader {
  color: $primary-blue-color;
}
 
.ant-table-wrapper .ant-table-tbody >tr {
  &.table__row--loading {
    >td {
      border-top: 1px solid $third-orange-color;
      border-bottom: 1px solid $third-orange-color;
      background-color: $first-yellow-color;
      &.ant-table-cell-row-hover {
        background-color: $first-yellow-color;
      }
      &:first-child {
        border-left: 1px solid $third-orange-color;
      }
      &:last-child {
        border-right: 1px solid $third-orange-color;
      }
    }
  }
  
  &.table__row--resently-liberated {
    >td {
      border-top: 1px solid $first-green-color;
      border-bottom: 1px solid $first-green-color;
      background-color: $third-green-color;
      &.ant-table-cell-row-hover {
        background-color: $third-green-color;
      }
      &:first-child {
        border-left: 1px solid $first-green-color;
      }
      &:last-child {
        border-right: 1px solid $first-green-color;
      }
    }
  }
}
