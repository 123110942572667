$primary-blue-color: #004CC6;
$primary-blue-hover: #80A5E2;
$secondary-blue-color: #15257A;
$primary-blue-ninth-color: #1A5ECC;
$secondary-blue-text-color: #B9BED7;
$light-blue-color: #4096ff;
$second-light-blue-color: #CFEDF9;
$warning-color: #FCEEEE;
$success-color: #F6FFED;
$error-color: #FFF7E8;
$box-shadow-color: rgba(0, 0, 0, 0.1);
$white-color: #FFFFFF;
$black-color: #000000;
$white-smoke-color: #F5F5F5;
$gray-text-color: #262626;
$secundary-gray-color: #FAFAFA;
$fifth-gray-color: #D9D9D9;
$sixth-gray-color: #BFBFBF;
$seventh-gray-color: #8C8C8C;
$eighth-gray-color: #595959;
$nineth-gray-color: #262626;
$second-green-color: #D9F7BE;
$eighth-green-color: #237804;
$ninth-green-color: #135200;
$sixth-red-color: #DB2929;
$second-yellow-color: #FFEED1;
$first-yellow-color: #FFF7E8;
$third-yellow-color: #FFE6BA;
$tenth-yellow-color: #FFAC1A;
$primary-orange-color: #FFECE5;
$second-orange-color: #FFD9CC;
$third-orange-color: #FFC5B3;
$fifth-orange-color: #FF9F80;
$tenth-orange-color: #FF4E04;

$first-green-color: #B7EB8F;
$third-green-color: #F6FFED;


$success-alert-background: #F6FFED;
$success-alert-border: #B7EB8F;

$collapse-card-background-color: #CCDBF4;
$primary-blue-light-color: #E5EDF9;
$collapse-active-border-color: #B3C9EE;

// Layout variables
$layout-space: 100px;
$layout-space-md: 15px;

// break points
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$content-padding: 0 $layout-space;
$content-margin: 0 $layout-space;
$content-padding-md: 0 $layout-space-md;
$content-margin-md: 0 $layout-space-md;

// Font variables
$primary-font: 'Source Sans Pro';
$secundary-font: sans-serif;

// regular
$primary-regular-font: 400 12px/20px $primary-font, $secundary-font;
$normal-font: 400 14px/16px $primary-font, $secundary-font;
$light-regular-font: 400 12px/12px $primary-font, $secundary-font;
$secundary-regular-font: 400 14px/22px $primary-font, $secundary-font;
$third-regular-font: 400 16px/24px $primary-font, $secundary-font;
// semibold
$primary-semibold-font: 600 12px/20px $primary-font, $secundary-font;
$second-semibold-font: 600 14px/22px $primary-font, $secundary-font;
$third-semibold-font: 600 16px/24px $primary-font, $secundary-font;
$fifth-semibold-font: 600 24px/32px $primary-font, $secundary-font;
// bold
$primary-bold-font: 700 12px/20px $primary-font, $secundary-font;
$bold-font: 700 14px/16px $primary-font, $secundary-font;
$secondary-bold-font: 700 14px/22px $primary-font, $secundary-font;
$third-bold-font: 700 16px/24px $primary-font, $secundary-font;
$fifth-bold-font: 700 24px/32px $primary-font, $secundary-font;
$light-bold-font: 700 16px/12px $primary-font, $secundary-font;
$seventh-bold-font: 700 38px/46px $primary-font, $secundary-font;
// others
$title-tab-inactive: 400 16px/18px $primary-font, $secundary-font;
$title-table: 600 14px/16px $primary-font, $secundary-font;
$secondary-title-custom-collapse: 600 16px/16px $primary-font, $secundary-font;
$title-tab-active: 600 16px/18px $primary-font, $secundary-font;
$principal-title-custom-collapse: 700 24px/24px $primary-font, $secundary-font;
$large-button-mobile: 700 20px/32px $primary-font, $secundary-font;
$loader-tab: 400 10px/10px $primary-font, $secundary-font;
// p
$p-small-font: 400 14px/22px $primary-font, $secundary-font;

// mixins buttons
@mixin disabled-button-blue-default {
  border: 1px solid $fifth-gray-color;
  background: $white-smoke-color;
  color: $sixth-gray-color;
}

@mixin hover-button-blue-default {
  border: 1px solid $primary-blue-hover;
  color: $primary-blue-hover;
}

@mixin disabled-button-blue-primary {
  background: $white-smoke-color;
  color: $sixth-gray-color;
}
@mixin hover-button-blue-primary {
  background: $primary-blue-hover;
}