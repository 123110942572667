@import "../../../resources/scss/variables";

header.header {
  background: white;
  box-shadow: 0px 3px 24px -4px $box-shadow-color;
  margin-bottom: 24px;
  padding: $content-padding;
  text-align: left;

  @media screen and (max-width: $md) {
    padding: $content-padding-md;
  }

  img.logo {
    vertical-align: middle;
    max-height: 42px;
    max-width: 167px;
  }

  ul.horizontal-menu {
    display: block;

    li {
      float: right;
      color: $secondary-blue-color;
      font-weight: 600;

      &.ant-menu-item-selected,
      &.ant-menu-item,
      &.ant-menu-item:hover,
      &.ant-menu-item:hover:not(.ant-menu-item-selected):not(.ant-menu-submenu-selected) {
        color: $secondary-blue-color;
      }

      &::after {
        display: none;
      }
    }
  }
  .user-menu-trigger{
    background: #E5EDF9;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    margin: auto;
    margin-left: 13px;

    svg{
      color: #80A5E2;
      margin: auto;
      height: 24px;
      width: 24px;
      margin-top: 9px;
    }
  }
}

.user-menu-overlay-content{
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;


  &>div:first-child{
    font-weight: 600;
  }
  &>div:last-child{
    margin-top: 10px;
  }
  a>span:last-child{
    margin-left: 9px;
    line-height: 22px;
  }
}