@import "../../../resources/scss/variables";

footer.footer {
  background: $secondary-blue-color;
  color: $secondary-blue-text-color;
  font-size: 12px;
  font-weight: 400;
  padding: $content-padding;
  padding-top: 11.5px;
  padding-bottom: 8.5px;
  margin-top: 25px;

  p {
    margin: 8px 0px 0px;
  }

  img.logo-footer {
    vertical-align: middle;
    max-height: 28px;
    max-width: 95px;
  }

  @media screen and (max-width: $md) {
    padding: $content-padding-md;
    padding-top: 11.5px;
    padding-bottom: 8.5px;
  }
}